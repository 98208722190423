import React from 'react'

interface Props {
  onClick?: () => void
  buttonTitle: string
}

export type IDataItem = { title: string; value: string }

export const DataItem: React.FC<IDataItem> = ({ title, value }) => {
  return (
    <div className="flex flex-col">
      <p className="text-description-subtle">{title}</p>
      <p className="font-semi dark:text-white text-[13px]">{value}</p>
    </div>
  )
}

const BasicRow: React.FC<Props> = ({ onClick, buttonTitle }) => {
  return (
    <div className="bg-brand-light-card dark:bg-brand-dark-card color-text-default justify-between p-4 rounded-lg flex flex-col md:items-center md:flex-row gap-5 md:gap-0">
      <div className="flex gap-6">
        <DataItem title="Navn" value="firstName lastName" />
        <DataItem title="Email" value="email@example.com" />
        <DataItem title="CVR" value="40857189" />
      </div>
      <div className="md:w-[130px]">
        <button onClick={onClick} className="button">
          {buttonTitle}
        </button>
      </div>
    </div>
  )
}

export default BasicRow
