import { Tab } from '../types/types'

interface Props {
  title?: string
  tabs?: Tab[]
}

function TabMenu({ title, tabs }: Props) {
  return (
    <div className="bg-brand-light-card dark:bg-brand-blue transition-all sticky left-0 top-0 right-0">
      <h1 className="font-semi text-md p-4 text-brand-light-text-default dark:text-brand-dark-text-default transition-all">
        {title}
      </h1>
      <div className="border-b-[1px] border-brand-light-card-border border-solid dark:border-brand-dark-card-border transition-all">
        {tabs && (
          <div className="md:mt-5 pl-5 flex items-center space-x-5 ">
            {tabs.map((tab, i) => {
              return (
                <div
                  key={i}
                  className={`font-semi text-xs cursor-pointer border-b-[3px] border-solid pb-2 px-2 transition-all ${
                    tab.active
                      ? 'border-blue text-brand-light-text-default dark:text-brand-dark-text-default'
                      : 'border-transparent text-brand-light-text-description dark:text-brand-dark-text-description'
                  }`}
                  onClick={tab.onPress}
                >
                  {tab.title}
                </div>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}

export default TabMenu
