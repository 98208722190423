import React from "react";
import { useHistory } from "react-router-dom";
import Form from "./components/Form";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

interface LoginScreenProps {}

type FormData = {
  email: string;
  password: string;
};

const schema: yup.SchemaOf<FormData> = yup.object({
  email: yup
    .string()
    .email("Please enter a valid email.")
    .required("This field is required."),
  password: yup
    .string()
    .min(8, "Password is too short - should be min. 8 characters.")
    .required("This field is required."),
});

const LoginScreen: React.FC<LoginScreenProps> = () => {
  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    reValidateMode: "onChange",
  });

  const onSubmit: SubmitHandler<FormData> = (data) => {
    console.log({ data });
  };

  return (
    <div className="bg-brand-background dark:bg-brand-dark-background bg h-screen flex flex-col justify-center items-center p-3">
      <Form direction="fromLeft" title="Login">
        <form className="space-y-3" onSubmit={handleSubmit(onSubmit)}>
          <div className="space-y-1">
            <label htmlFor="email" className="text-description">
              Email
            </label>

            <input
              className="input"
              type="text"
              id={"email"}
              {...register("email")}
            />
            {errors?.email?.message && (
              <span className="text-warning">{errors?.email?.message}</span>
            )}
          </div>
          <div className="space-y-1">
            <label htmlFor="password" className="text-description">
              Password
            </label>

            <input
              className="input"
              type="text"
              id={"password"}
              {...register("password")}
            />
            {errors?.password?.message && (
              <span className="text-warning">{errors?.password?.message}</span>
            )}
          </div>

          <div>
            <button type="submit" className="button mb-7">
              Login
            </button>

            <div className="text-center text-xs">
              <span className="font-medium text-brand-light-text-description dark:text-brand-dark-text-description">
                Forgot password?
              </span>{" "}
              <button
                onClick={() => history.push("/forgot-password")}
                className="font-semi text-brand-accent hover:text-brand-accent-hover hover:underline"
              >
                Reset here
              </button>
            </div>
          </div>
        </form>
      </Form>
    </div>
  );
};

export default LoginScreen;
