import { motion } from 'framer-motion'
import React from 'react'

interface Props {
  isOn: boolean
}

const Switch: React.FC<Props> = ({ isOn, ...restProps }) => {
  const className = `switch ${isOn ? 'on' : 'off'}`

  const spring = {
    type: 'spring',
    stiffness: 700,
    damping: 30,
    duration: 2,
  }

  return (
    <motion.div data-isOn={isOn} className={className} {...restProps}>
      <motion.div animate layout transition={spring} />
    </motion.div>
  )
}

export default Switch
