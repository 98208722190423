import { useToast } from "@chakra-ui/react";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Form from "./components/Form";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

interface ForgotPasswordScreenProps {}

type FormData = {
  email: string;
};

const schema: yup.SchemaOf<FormData> = yup.object({
  email: yup
    .string()
    .email("Please enter a valid email.")
    .required("This field is required."),
});

const ForgotPasswordScreen: React.FC<ForgotPasswordScreenProps> = () => {
  const history = useHistory();
  const toast = useToast();
  const [showConfirmation, setShowConfirmation] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    reValidateMode: "onChange",
  });

  const onSubmit: SubmitHandler<FormData> = (data) => {
    console.log({ data });
    setShowConfirmation(true);
  };

  const ConfirmContainer = () => {
    return (
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0, y: 40 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 40 }}
          transition={{ ease: "easeOut", duration: 0.4 }}
          className="flex flex-col w-full items-center max-w-[450px] p-10 rounded-2xl bg-brand-light-card dark:bg-brand-dark-card"
        >
          <FontAwesomeIcon
            icon={faEnvelope}
            className="text-brand-accent text-xl"
          />
          <h1 className="color-text-default font-semi mb-5 mt-3 text-xl">
            Check your mail
          </h1>
          <p className="text-description text-center">
            We have sent you a mail on
            <span className="font-semi text-brand-accent">
              {" "}
              thomas@penning.dk
            </span>
            , click the link in it to reset your password.
          </p>
          <button onClick={() => history.push("/")} className="button mt-10">
            Back to login
          </button>
        </motion.div>
      </AnimatePresence>
    );
  };

  return (
    <div className="bg-brand-background dark:bg-brand-dark-background bg h-screen flex flex-col justify-center items-center p-3">
      {showConfirmation ? (
        <ConfirmContainer />
      ) : (
        <Form
          direction="fromRight"
          title="Reset my password"
          onPressBack={() => history.push("/")}
        >
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-3">
            <div className="space-y-1">
              <label htmlFor="email" className="text-description">
                Email
              </label>

              <input
                className="input"
                type="text"
                id={"email"}
                {...register("email")}
              />
              {errors?.email?.message && (
                <span className="text-warning">{errors?.email?.message}</span>
              )}
            </div>

            <button type="submit" className="button">
              Submit
            </button>
          </form>
        </Form>
      )}
    </div>
  );
};

export default ForgotPasswordScreen;
