import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import AppNavigator from './AppNavigator'
import LoginScreen from './screens/Login/LoginScreen'
import ForgotPasswordScreen from './screens/Login/ForgotPasswordScreen'
import NotFoundScreen from './screens/App/screens/Not Found/NotFoundScreen'

export default function App() {
  return (
    <div className="h-screen overflow-hidden">
      <Router>
        <Switch>
          <Route path="/app">
            <AppNavigator />
          </Route>
          <Route exact path="/forgot-password">
            <ForgotPasswordScreen />
          </Route>
          <Route exact path="/">
            {/* Warning: LoginScreen (and everything not under AppNavigator) is
          not checked by AppAuthorizationChecker, meaning that the SessionState
          is not type-safe */}
            <LoginScreen />
          </Route>

          <Route path="*">
            <NotFoundScreen />
          </Route>
        </Switch>
      </Router>
    </div>
  )
}
