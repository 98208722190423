import Navigator from './Navigator'
import './reset.css'
import { Provider } from 'react-redux'
import store from './redux/store'
import { ChakraProvider } from '@chakra-ui/react'
import chakraTheme from './chakraTheme'
import { useEffect } from 'react'

function App() {
  useEffect(() => {
    if (localStorage.getItem('color-theme') === 'dark') {
      document.documentElement.classList.add('dark')
      document.body.setAttribute('theme', 'dark')

      document.body.classList.remove('html-light')
      document.body.classList.add('html-dark')
    } else {
      document.documentElement.classList.remove('dark')
      document.body.setAttribute('theme', 'light')
    }
  }, [])

  return (
    <Provider store={store}>
      <ChakraProvider theme={chakraTheme} resetCSS={true}>
        <Navigator />
      </ChakraProvider>
    </Provider>
  )
}

export default App
