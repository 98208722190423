import React from "react";
import { Tab } from "../types/types";
import TabMenu from "./TabMenu";

interface PageWrapperProps {
  children: React.ReactNode;
  title?: string;
  tabs?: Tab[];
  fixedWidth?: boolean;
}

const PageWrapper: React.FC<PageWrapperProps> = ({
  children,
  title,
  tabs,
  fixedWidth,
}) => {
  return (
    <div className="h-screen relative">
      <TabMenu title={title} tabs={tabs} />

      <div className="flex flex-col items-center overflow-scroll ">
        <div
          className={`w-full p-3 h-full flex flex-col ${
            fixedWidth ? "max-w-3xl mt-5" : "p-5"
          } `}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default PageWrapper;
