import { HStack, Text } from '@chakra-ui/layout'
import { Box, Button, Heading } from '@chakra-ui/react'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, Redirect, useLocation } from 'react-router'
import { endSession } from '../redux/slices/sessionSlice'
import { RootState } from '../redux/store'
import moment from 'moment'

const AppAuthorizationChecker: React.FC = ({ children }) => {
  const session = useSelector((state: RootState) => state.session)
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  const authorized = true // TODO: Template authorization logic here

  if (!authorized) {
    return (
      <Box p="12">
        <Heading mb={4}>Forbudt adgang</Heading>
        <Text mb={2} maxW={['90vw', '90vw', '50vw']}>
          Det ser ikke ud til at du er logget ind i systemet, og er derfor ikke
          tilladt adgang til applikationen. Prøv venligst at logge helt ud, og
          logge ind igen, hvis dette virker som en fejl.
        </Text>
        <HStack>
          <Button
            // btnType="warning"
            onClick={() => {
              dispatch(endSession())
              history.push('/')
            }}
          >
            Log ud
          </Button>
        </HStack>
      </Box>
    )
  }
  return <>{children}</>
}

export default AppAuthorizationChecker
