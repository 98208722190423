import {
  faArrowsRotate,
  faLeftRight,
  faShield,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import ChangeThemeCard from "../../../../components/ChangeThemeCard";
import DefaultSettingContainer from "../../../../components/DefaultSettingContainer";
import PageWrapper from "../../../../components/Pagewrapper";
import PersonalDetailItem from "../../../../components/PersonalDetailItem";
import SwitchSettingContainer from "../../../../components/SwitchSettingContainer";

function SettingsScreen() {
  const changeDynamicSidebarPreference = () => {
    if (localStorage.getItem("dynamic-sidebar") === "on") {
      localStorage.setItem("dynamic-sidebar", "off");
    } else {
      localStorage.setItem("dynamic-sidebar", "on");
    }
    window.dispatchEvent(new Event("storage"));
  };

  const [dynamicSidebar, setDynamicSidebar] = useState(
    localStorage.getItem("dynamic-sidebar") === "on"
  );

  return (
    <PageWrapper title="Settings" fixedWidth>
      <h2 className="h2 mb-3 mt-10">Account information</h2>
      <div className="card gap-2 md:gap-4">
        <PersonalDetailItem title="Full name" value="Martin Chrillesen" />
        <PersonalDetailItem title="Role" value="Admin" />
      </div>
      <h2 className="h2 mb-3 mt-10">Appearance</h2>
      <ChangeThemeCard />

      <SwitchSettingContainer
        title="Dynamic sidebar"
        description="Decide whether or not the sidebar should resize when hovering."
        enabled={dynamicSidebar}
        iconRender={<FontAwesomeIcon icon={faLeftRight} size="lg" />}
        onChange={() => {
          setDynamicSidebar(!dynamicSidebar);
          changeDynamicSidebarPreference();
        }}
      />
    </PageWrapper>
  );
}

export default SettingsScreen;
