import React, { useState } from 'react'
import BasicRow, { DataItem } from '../../../../components/BasicRow'
import PageWrapper from '../../../../components/Pagewrapper'
import { useDisclosure } from '@chakra-ui/react'
import DefaultModal from '../../../../components/DefaultModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowsLeftRight, faLink } from '@fortawesome/free-solid-svg-icons'
import newbanking from '../../../../assets/newbanking.png'
import penning from '../../../../assets/icon-white.png'

const KYCRequestsScreen: React.FC = () => {
  const uuidModal = useDisclosure()
  const confirmModal = useDisclosure()
  const [query, setQuery] = useState('')

  return (
    <PageWrapper title="KYC Requests" fixedWidth>
      <DefaultModal
        isOpen={uuidModal.isOpen}
        isLoading={false}
        onClose={() => {
          setQuery('')
          uuidModal.onClose()
          setTimeout(() => {
            confirmModal.onOpen()
          }, 300)
        }}
        title="Indtast UUID"
        subtitle="Go to Newbanking and check bla bla bla"
      >
        <div className="p-5 space-y-2">
          <input
            value={query}
            onChange={e => setQuery(e.target.value)}
            className="input"
            placeholder="Indtast UUID"
          />
          <button className="button">Find client</button>
        </div>
      </DefaultModal>
      <DefaultModal
        isOpen={confirmModal.isOpen}
        isLoading={false}
        onClose={() => {
          confirmModal.onClose()
        }}
        title="Confirm link"
        subtitle="Are you sure you want to link the following NewBanking user and penning user together?"
      >
        <div className="m-5 space-y-4 border-2 border-solid border-brand-light-card-border dark:border-brand-dark-card-border p-2 rounded-xl">
          <div className="bg-brand-light-card dark:bg-brand-dark-card p-4 rounded-lg flex items-center gap-4">
            <img src={newbanking} className="w-9 h-9" />
            <DataItem title="jej" value="jek" />
          </div>
          <div className="flex justify-center">
            <FontAwesomeIcon
              icon={faLink}
              className="text-brand-light-text-description dark:text-brand-dark-text-description"
            />
          </div>
          <div className="bg-brand-light-card dark:bg-brand-dark-card p-4 rounded-lg flex items-center gap-4">
            <img src={penning} className="w-9 h-9" />
            <DataItem title="jej" value="jek" />
          </div>
        </div>
        <div className="p-5">
          <button className="button">Confirm link</button>
        </div>
      </DefaultModal>
      <div className="space-y-2">
        <BasicRow buttonTitle="Link" onClick={uuidModal.onOpen} />
      </div>
    </PageWrapper>
  )
}

export default KYCRequestsScreen
