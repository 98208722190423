import React from "react";
import { Tab } from "../../../../../types/types";
import TabMenu from "../../../../../components/TabMenu";

interface Props {
  title: string;
  amount?: string;
  isActive: boolean;
  onClick: () => void;
}

const DepositMenuTabItem: React.FC<Props> = ({
  title,
  amount,
  isActive,
  onClick,
}) => {
  return (
    <div
      onClick={onClick}
      className={`select-none cursor-pointer  ${
        isActive
          ? "bg-brand-blue hover:bg-brand-blue-card dark:bg-brand-accent dark:hover:bg-brand-accent-hover text-brand-dark-text-default"
          : "bg-brand-light-card  text-brand-light-text-default dark:bg-brand-dark-card dark:hover:bg-brand-dark-card-border dark:text-brand-dark-text-default"
      } text-xs font-semi px-3.5 py-2 rounded-full flex items-center gap-2 transition-all`}
    >
      {title}
      {amount && (
        <div
          className={` ${
            isActive
              ? "bg-brand-light-card text-brand-light-text-default"
              : "text-brand-light-text-default bg-brand-light-card-border dark:bg-brand-blue dark:text-brand-dark-text-default"
          } h-[22px] flex items-center justify-center min-w-[22px] px-2 rounded-full text-[10px] transition-all`}
        >
          {amount}
        </div>
      )}
    </div>
  );
};

export default DepositMenuTabItem;
