import React from 'react'
import Switch from './Switch'

interface Props {
  iconRender: React.ReactNode
  title: string
  description: string
  enabled: boolean
  onChange: () => void
}

const ChangeSettingContainer: React.FC<Props> = ({
  iconRender,
  title,
  description,
  enabled,
  onChange,
  ...restProps
}) => {
  return (
    <div className="card mt-2 cursor-pointer select-none" onClick={onChange}>
      <div className="flex flex-row ">
        <div className="text-brand-accent w-[40px]">{iconRender}</div>
        <div className="flex-grow">
          <p className="text-default mb-1">{title}</p>
          <p className="text-description">{description}</p>
        </div>
        <div className="flex items-center w-[30%] justify-end">
          <Switch isOn={enabled} />
        </div>
      </div>
    </div>
  )
}

export default ChangeSettingContainer
