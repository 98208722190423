export const NODE_ENV = process.env.NODE_ENV
export const SENTRY_DSN =
  process.env.REACT_APP_SENTRY_DSN ||
  'https://2e7c5d013f59491292e9400e1fcfc746@o412076.ingest.sentry.io/5879540'
export const API_URL =
  process.env.REACT_APP_API_URL || 'https://api.dietplan.dk'

if (NODE_ENV === 'development') {
  console.warn('*************** DEVELOPMENT ***************')
}
if (!SENTRY_DSN) {
  console.warn('Warning: Sentry DSN not set!')
}
if (!API_URL) {
  console.error('Error: No api url defined - the website will not work.')
} else if (API_URL.charAt(API_URL.length - 1) === '/') {
  console.error(
    "Error: api url is incorrectly defined, it must not end with a '/'"
  )
}
