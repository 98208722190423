import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnimatePresence, motion } from "framer-motion";
import React from "react";

interface FormProps {
  title: string;
  direction: "fromLeft" | "fromRight";
  onPressBack?: () => void;
}

const Form: React.FC<FormProps> = ({
  children,
  direction,
  title,
  onPressBack,
}) => {
  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, x: direction === "fromLeft" ? -30 : 30 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: direction === "fromLeft" ? -30 : 30 }}
        transition={{ ease: "easeOut", duration: 0.4 }}
        className="flex flex-col w-full max-w-[550px] p-10 rounded-2xl bg-brand-light-card dark:bg-brand-dark-card"
      >
        {onPressBack && (
          <div
            onClick={onPressBack}
            className="flex items-center color-text-description hover:text-black dark:hover:text-white cursor-pointer text-[13px] gap-2 mb-4"
          >
            <FontAwesomeIcon icon={faArrowLeft} className="" />
            Back to login
          </div>
        )}
        <h1 className="color-text-default font-semi mb-5">{title}</h1>
        {children}
      </motion.div>
    </AnimatePresence>
  );
};

export default Form;
