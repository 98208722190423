import React from "react";

interface Props {
  onClick?: () => void;
}

const DepositRow: React.FC<Props> = ({ onClick }) => {
  type DataItemProps = { title: string; value: string };
  const DataItem: React.FC<DataItemProps> = ({ title, value }) => {
    return (
      <div className="flex flex-col">
        <p className="text-description-subtle">{title}</p>
        <p className="text-default">{value}</p>
      </div>
    );
  };

  return (
    <div className="bg-brand-light-card dark:bg-brand-dark-card color-text-default justify-between p-4 rounded-lg flex flex-col md:items-center md:flex-row gap-5 md:gap-0">
      <div className="flex gap-5 ">
        <DataItem title="Unique ID" value="4283-ddf1-p319-d103" />
        <DataItem title="Amount" value="50.000 EUR" />
        <DataItem title="User" value="firstName lastName" />
      </div>
      <div className="md:w-[130px]">
        <button onClick={onClick} className="button">
          Mark as paid
        </button>
      </div>
    </div>
  );
};

export default DepositRow;
