import { configureStore } from '@reduxjs/toolkit'
import { exampleApi } from './services/exampleApi'
import sessionReducer from './slices/sessionSlice'
import { setupListeners } from '@reduxjs/toolkit/query'
import { rtkQueryErrorLogger } from './rtkQueryErrorLogger'

const store = configureStore({
  reducer: {
    session: sessionReducer,
    [exampleApi.reducerPath]: exampleApi.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat([exampleApi.middleware, rtkQueryErrorLogger]),
})
// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store
