import React from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'
import AppAuthorizationChecker from './containers/AppAuthorizationChecker'
import AppMenu from './screens/App/components/AppMenu'
import ActivityLogScreen from './screens/App/screens/Activity Log/ActivityLogScreen'
import DepositsScreen from './screens/App/screens/Deposits/DepositsScreen'
import KYCRequestsScreen from './screens/App/screens/KYCRequests/KYCRequestsScreen'
import SettingsScreen from './screens/App/screens/Settings/SettingsScreen'

interface AppNavigatorProps {}

const AppNavigator: React.FC<AppNavigatorProps> = () => {
  let { path } = useRouteMatch()

  return (
    <AppAuthorizationChecker>
      <div className="h-screen flex flex-col w-full md:flex-row ">
        <AppMenu />

        <div className="w-full bg-brand-light-background dark:bg-brand-dark-background transition-all h-screen overflow-scroll">
          <Switch>
            <Route path={`${path}/transfers`}>
              <DepositsScreen />
            </Route>
            <Route path={`${path}/kyc-requests`}>
              <KYCRequestsScreen />
            </Route>
            {/* <Route path={`${path}/activity-log`}>
              <ActivityLogScreen />
            </Route> */}
            <Route path={`${path}/settings`}>
              <SettingsScreen />
            </Route>
          </Switch>
        </div>
      </div>
    </AppAuthorizationChecker>
  )
}

export default AppNavigator
