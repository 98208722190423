import React from 'react'
import { useHistory } from 'react-router-dom'

interface Props {}

const NotFoundScreen: React.FC<Props> = () => {
  const history = useHistory()
  return (
    <div className="bg-brand-background dark:bg-brand-dark-background bg h-screen flex flex-col justify-center items-center ">
      <h1 className="color-text-default font-semi text-2xl mb-2">Not found</h1>
      <p className="font-medium color-text-description mb-10">
        We couldn’t find the page you were looking for, please return to home
        and try again.
      </p>
      <div className="w-[200px]">
        <button onClick={() => history.push('/')} className="button">
          Return to home
        </button>
      </div>
    </div>
  )
}

export default NotFoundScreen
