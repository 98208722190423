import React, { useState } from "react";
import DepositMenuTabItem from "./components/DepositMenuTabItem";
import PageWrapper from "../../../../components/Pagewrapper";
import DepositRow from "./components/DepositRow";
import DefaultModal from "../../../../components/DefaultModal";
import { useDisclosure, useToast } from "@chakra-ui/react";

interface Props {}

const DepositsScreen: React.FC<Props> = ({}) => {
  type Tabs = "pending" | "processing" | "completed";

  const toast = useToast();
  const [tab, setTab] = useState<Tabs>("pending");
  const confirmModal = useDisclosure();
  const [loading, setLoading] = useState(false);

  type DataItemProps = { title: string; value: string };
  const DataItem: React.FC<DataItemProps> = ({ title, value }) => {
    return (
      <div className="flex flex-col">
        <p className="text-description-subtle mb-1">{title}</p>
        <p className="text-default">{value}</p>
      </div>
    );
  };

  const NoResults = ({ title }: { title: string }) => {
    return (
      <div className="text-center">
        <p className="text-default">No results</p>
        <p className="text-description">{title}</p>
      </div>
    );
  };

  return (
    <PageWrapper title="Transfers" fixedWidth>
      <DefaultModal
        isLoading={loading}
        isOpen={confirmModal.isOpen}
        onClose={confirmModal.onClose}
        title="Confirm transaction"
        maxW="500px"
        hideCloseButton
        loadingTitle="Confirming transaction..."
      >
        <div className=" flex flex-col items-center p-5 pt-0">
          <p className="text-description">
            Are you sure you want to confirm a transaction with the following
            info and move it to processing?
          </p>
          <div className="flex items-center w-full p-3 rounded-lg bg-brand-light-card dark:bg-brand-dark-card mt-5 gap-5">
            <DataItem title="Unique ID" value="4283-ddf1-p319-d103" />
            <DataItem title="Amount" value="50.000 EUR" />
            <DataItem title="User" value="firstName lastName" />
          </div>
          <div className="flex w-full gap-2 mt-10">
            <button onClick={confirmModal.onClose} className="button-secondary">
              Cancel
            </button>
            <button onClick={() => setLoading(true)} className="button">
              Confirm
            </button>
          </div>
        </div>
      </DefaultModal>
      <div className="flex flex-col md:flex-row md:items-center gap-5 md:gap-0 justify-between mt-10 mb-14">
        <div className="flex gap-2">
          {/* <button onClick={() => setLoading(false)}>reset</button>
          <button
            onClick={() =>
              toast({
                description:
                  "Transaction with id '4283-ddf1-p319-d103' confirmed and moved to processing.",
                position: "bottom-right",
                status: "success",
                duration: 10000,
                isClosable: true,
              })
            }
          >
            toast
          </button> */}
          <DepositMenuTabItem
            title="Pending"
            amount={"3"}
            isActive={tab === "pending"}
            onClick={() => setTab("pending")}
          />
          <DepositMenuTabItem
            title="Processing"
            amount={"0"}
            isActive={tab === "processing"}
            onClick={() => setTab("processing")}
          />
          <DepositMenuTabItem
            title="Completed"
            amount={"0"}
            isActive={tab === "completed"}
            onClick={() => setTab("completed")}
          />
        </div>

        <input
          className="input-card md:max-w-[300px]"
          placeholder="Search by Unique ID"
          type="text"
          name="search"
          id="search-for-deposit"
        />
      </div>
      <div className="space-y-2">
        {tab === "pending" && (
          <>
            <DepositRow onClick={confirmModal.onOpen} />
            <DepositRow onClick={confirmModal.onOpen} />
            <DepositRow onClick={confirmModal.onOpen} />
          </>
        )}
        {tab === "processing" && (
          <NoResults title="There are currently no processing deposits." />
        )}
        {tab === "completed" && (
          <NoResults title="There are currently no completed deposits." />
        )}
      </div>
    </PageWrapper>
  );
};

export default DepositsScreen;
