import React, { useReducer } from "react";
import { useHistory } from "react-router-dom";
import { RiMenu2Line } from "react-icons/ri";
import {
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  useDisclosure,
} from "@chakra-ui/react";
import { MdOutlineClose } from "react-icons/md";
import logo from "../../../assets/icon-white.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRightLeft,
  faList,
  faUser,
  faBell,
  faRightFromBracket,
  faGear,
  faUserShield,
} from "@fortawesome/free-solid-svg-icons";

interface Props {}

const AppMenu: React.FC<Props> = () => {
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const pages = [
    {
      title: "Transfers",
      path: "/app/transfers",
      icon: <FontAwesomeIcon icon={faRightLeft} className="text-xs" />,
    },
    {
      title: "KYC requests",
      path: "/app/kyc-requests",
      icon: <FontAwesomeIcon icon={faUserShield} size="sm" />,
    },
    // {
    //   title: "Activity log",
    //   path: "/app/activity-log",
    //   icon: <FontAwesomeIcon icon={faList} size="sm" />,
    // },
    {
      title: "Settings",
      path: "/app/settings",
      icon: <FontAwesomeIcon icon={faGear} size="sm" />,
    },
  ];

  window.addEventListener("storage", () => {
    forceUpdate();
  });

  const isDynamicSidebarActivated = () => {
    return localStorage.getItem("dynamic-sidebar") === "on";
  };

  const MenuItem = ({
    isActive,
    onClick,
    title,
    icon,
  }: {
    isActive: boolean;
    onClick: () => void;
    title: string;
    icon: JSX.Element;
  }) => {
    return (
      <div
        className={`w-full transition-all text-white p-1.5 rounded-xl flex items-center select-none group cursor-pointer ${
          isActive ? "bg-brand-blue-card" : ""
        }`}
        onClick={onClick}
      >
        <div
          className={`h-9 w-9 flex-shrink-0 flex items-center justify-center rounded-lg ${
            isActive ? "bg-brand-accent" : "bg-brand-blue-card"
          }`}
        >
          {icon}
        </div>
        <p
          className={`${
            isDynamicSidebarActivated()
              ? "flex md:hidden group-hover:flex"
              : "flex"
          }  whitespace-nowrap overflow-hidden text-white ml-2.5 font-semi text-xs`}
        >
          {title}
        </p>
      </div>
    );
  };

  return (
    <div
      className={`${
        isDynamicSidebarActivated()
          ? "md:w-[68px] w-full md:hover:w-[280px]"
          : "w-full md:w-[280px]"
      } h-[60px] md:h-full w-full transition-all duration-300 group bg-brand-blue flex md:flex-col p-2  border-r-[1px] border-solid border-transparent dark:border-r-brand-dark-card-border z-50`}
    >
      {/* <div className="text-brand-light-text-description font-medium text-xs p-4">
        PENNING ADMIN
      </div> */}
      <div className="hidden md:flex md:flex-col gap-1 justify-between pt-14 h-full">
        <div>
          {pages.map((item, i) => {
            const isActive = history.location.pathname.includes(item.path);
            return (
              <MenuItem
                key={i}
                icon={item.icon}
                title={item.title}
                onClick={() => history.push(item.path)}
                isActive={isActive}
              />
            );
          })}
        </div>
        {/* Log out */}
        <div
          className={`w-full transition-all text-white p-1.5 rounded-xl flex items-center select-none group cursor-pointer `}
          onClick={() => history.push("/")}
        >
          <div
            className={`h-9 w-9 flex-shrink-0 flex items-center justify-center rounded-lg bg-brand-blue-card text-brand-red`}
          >
            <FontAwesomeIcon icon={faRightFromBracket} size="sm" />
          </div>
          <p
            className={`${
              isDynamicSidebarActivated() ? "hidden group-hover:flex" : "flex"
            }  whitespace-nowrap overflow-hidden text-brand-red ml-2.5 font-semi text-xs`}
          >
            Log out
          </p>
        </div>
      </div>
      <div className="md:hidden text-white flex items-center px-1 ">
        <div className="p-1" onClick={onOpen}>
          <RiMenu2Line color="#fff" size={24} />
        </div>
        <Drawer isOpen={isOpen} placement="left" onClose={onClose} size="full">
          <DrawerOverlay />
          <DrawerContent bg="#1F2F47">
            <div className="flex items-center justify-between px-5 pt-5">
              {/* <img className="w-[50px]" src={logo} alt="Penning logo" /> */}

              <div className="p-4 rounded-full" onClick={onClose}>
                <MdOutlineClose size={26} color="#fff" />
              </div>
            </div>

            <DrawerBody mt="20px">
              <div className="flex flex-col gap-2">
                {pages.map((item, i) => {
                  const isActive = history.location.pathname.includes(
                    item.path
                  );

                  return (
                    <MenuItem
                      key={i}
                      icon={item.icon}
                      isActive={isActive}
                      onClick={() => {
                        history.push(item.path);
                        onClose();
                      }}
                      title={item.title}
                    />
                    // <div
                    //   key={i}
                    //   className="text-white font-semi text-lg py-5"
                    //   onClick={() => {
                    //     history.push(item.path)
                    //     onClose()
                    //   }}
                    // >
                    //   {item.title}
                    // </div>
                  );
                })}
              </div>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </div>
    </div>
  );
};

export default AppMenu;
